export const setS_storage = (name,val)=>{
    sessionStorage.setItem(name,JSON.stringify(val))
}
export const getS_storage = (name)=>{
  return JSON.parse(sessionStorage.getItem(name)) || []
}
export const setL_storage = (name,val)=>{
    localStorage.setItem(name,JSON.stringify(val))
}
export const getL_storage = (name)=>{
   return JSON.parse(localStorage.getItem(name)) || []
}
 // 除以100保留两位小数点
export const divide=(str,type)=> {
  let floatVal = parseFloat(str);
  if (isNaN(floatVal )) {
    return 0;
  }
  floatVal = Math.round(str * 100) / 10000;
  let strVal = floatVal .toString();
  let searchVal = strVal.indexOf('.');
  if (searchVal < 0) {
    searchVal = strVal.length;
    strVal += '.';
  }
  while (strVal.length <= searchVal + 2) {
    strVal += '0';
  } 
  // return strVal
  if(type == 'float'){
    return parseFloat(strVal);
  }else {
    return strVal
  }
}
// 乘以100保留两位小数点
export const multiply=(str) =>{
  let floatVal = parseFloat(str);
  if (isNaN(floatVal)) {
    return 0;
  }
  floatVal = Math.round(str * 10000) / 100;
  let strVal = floatVal.toString();
  let searchVal = strVal.indexOf('.');
  if (searchVal < 0) {
    searchVal = strVal.length;
    strVal += '.';
  }
  while (strVal.length <= searchVal + 2) {
    strVal += '0';
  }
  return parseFloat(strVal);// parseFloat 正整数不加.00. 去除parseFloat加.00
}
//   防抖 300毫秒内多次点击只生效一次
export const debounce = function (func, delay = 300) {
  let context = this 
  let args = arguments;
  return function () {
    if (context.timeout) {
      clearTimeout(context.timeout);
    }
    context.timeout = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};
//   节流 
export const throttle = function(func,delay = 300){
  let context = this
  let args = arguments;
  return function(){
    if (context.timeout) {
      return
    }
    context.timeout = setTimeout(() => {
      func.apply(context, args);
      context.timeout = null;
    },delay)
  }
}
/* 排序 */
export const sortBy =function (attr,rev){
  if( rev==undefined ){ rev=1 }else{ (rev)?1:-1; }
  return function (a,b){
      a=a[attr];
      b=b[attr];
      if(a<b){ return rev*-1}
      if(a>b){ return rev* 1 }
      return 0;
  }
};
// 取得[n,m]范围随机整数 
export const fullClose = function(n,m) {
  var result =  Math.floor(Math.random() * (m - n + 1)) + n; //+1包含 m
  return result;
};
//任意长度随机字符串
export const randomString = function(len) {
  len = len || 32;
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';   
  var maxPos = $chars.length;
  var pwd = '';
  for (let c = 0; c < len;  c++) {
  pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};
//任意长度随机字符串 大写
export const randomLeString = function(len) {
  len = len || 32;
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZ';   
  var maxPos = $chars.length;
  var pwd = '';
  for (let c = 0; c < len;  c++) {
  pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};
//任意长度随机字数字
export const randomNumber = function(len) {
  len = len || 32;
  var $chars = '0123456789';   
  var maxPos = $chars.length;
  var pwd = '';
  for (let j = 0; j < len; j++) {
     pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;

};